import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { IEPGProgramState, IEPGState, ITimelineDataState2 } from '../../Store/Timeline/Types';
import { Logger } from '../../Utils/Logger';
import { dateFromProto } from '../../Utils/Time';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../Utils/Various';

export class EPG extends AMessage implements IFromCommand {
    private from: Date | null = null;
    private to: Date | null = null;
    private mediaID: number | null = null;
    private audiences: IEPGState | null = null;
    private annotations: IEPGState | null = null;
    private background: IEPGState | null = null;
    private backgroundstg: IEPGState | null = null;
    private declarative: IEPGState | null = null;
    private music: IEPGState | null = null;
    private readjusted1: IEPGState | null = null;
    private readjusted2: IEPGState | null = null;
    private talk: IEPGState | null = null;
    private stt: IEPGState | null = null;
    private broadcaster1: IEPGState | null = null;
    private broadcaster2: IEPGState | null = null;
    private declarativeRadio1: IEPGState | null = null;
    private declarativeRadio2: IEPGState | null = null;
    private raw1: IEPGState | null = null;
    private raw2: IEPGState | null = null;
    private raw3: IEPGState | null = null;
    private raw4: IEPGState | null = null;
    private nikitaMonobucket1: IEPGState | null = null;
    private nikitaMonobucket2: IEPGState | null = null;
    private nikitaMonobucket3: IEPGState | null = null;
    private nikitaMonobucket4: IEPGState | null = null;
    private leonV11: IEPGState | null = null;
    private leonV12: IEPGState | null = null;
    private leonV13: IEPGState | null = null;
    private leonV14: IEPGState | null = null;
    private extract: IEPGState | null = null;
    private arpp: IEPGState | null = null;
    private mnmxb1: IEPGState | null = null;
    private mnmxb2: IEPGState | null = null;
    private mnionly1: IEPGState | null = null;
    private mnionly2: IEPGState | null = null;
    private sponsoring: IEPGState | null = null;
    private crossbucket: IEPGState | null = null;
    private crossbucketstg: IEPGState | null = null;
    private mnmRaw: IEPGState | null = null;
    private mnmProcessed: IEPGState | null = null;

    public constructor() {
        super('epg');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.EPGArgs)) {
                return reject(new Error('Not an EPG message'));
            }
            if (isNullOrUndefinedOrZero(msg.EPGArgs.From)) {
                return reject(new Error('Invalid from timestamp'));
            }
            if (isNullOrUndefinedOrZero(msg.EPGArgs.To)) {
                return reject(new Error('Invalid to timestamp'));
            }
            if (isNullOrUndefinedOrZero(msg.EPGArgs.MediaID)) {
                return reject(new Error('Invalid media ID'));
            }
            if (isNullOrUndefined(msg.EPGArgs.Data)) {
                return reject(new Error('Invalid or empty EPG data'));
            }
            this.from = dateFromProto(msg.EPGArgs.From);
            this.to = dateFromProto(msg.EPGArgs.To);
            this.mediaID = msg.EPGArgs.MediaID;
            msg.EPGArgs.Data.forEach((i) => {
                switch (i.Type) {
                    case Proto.mediaarchiver.EPGType.AUDIENCE:
                        this.audiences = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.ANNOTATIONS:
                        this.annotations = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.BACKGROUND:
                        this.background = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.BACKGROUNDSTG:
                        this.backgroundstg = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.ARPP:
                        this.arpp = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.MNMXB0:
                        this.mnmxb1 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.MNMXB1:
                        this.mnmxb2 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.MNIONLY0:
                        this.mnionly1 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.MNIONLY1:
                        this.mnionly2 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.SPONSORING:
                        this.sponsoring = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.DECLARATIVE:
                        this.declarative = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.MUSIC:
                        this.music = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.READJUSTED1:
                        this.readjusted1 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.READJUSTED2:
                        this.readjusted2 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.TALK:
                        this.talk = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.STT:
                        this.stt = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.BROADCASTER1:
                        this.broadcaster1 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.BROADCASTER2:
                        this.broadcaster2 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.DECLARATIVERADIO1:
                        this.declarativeRadio1 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.DECLARATIVERADIO2:
                        this.declarativeRadio2 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.BRUTPHONO1:
                        this.raw1 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.BRUTPHONO2:
                        this.raw2 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.BRUTPHONO3:
                        this.raw3 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.BRUTPHONO4:
                        this.raw4 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET1:
                        this.nikitaMonobucket1 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET2:
                        this.nikitaMonobucket2 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET3:
                        this.nikitaMonobucket3 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET4:
                        this.nikitaMonobucket4 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.MNM0:
                        this.mnmRaw = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.MNM1:
                        this.mnmProcessed = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.LEONV11:
                        this.leonV11 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.LEONV12:
                        this.leonV12 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.LEONV13:
                        this.leonV13 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.LEONV14:
                        this.leonV14 = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.EXTRACTRESULTS:
                        this.extract = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.CROSSBUCKET:
                        this.crossbucket = this.protoEPGToStoreEPG(i);
                        break;
                    case Proto.mediaarchiver.EPGType.CROSSBUCKETSTG:
                        this.crossbucketstg = this.protoEPGToStoreEPG(i);
                        break;
                    default:
                        Logger.warn({ type: i.Type }, 'Unknown EPG type');
                }
            });
            resolve();
        });
    }

    public getFrom(): Date | null {
        return this.from;
    }

    public getTo(): Date | null {
        return this.to;
    }

    public getMedia(): number | null {
        return this.mediaID;
    }

    public getEPG(): ITimelineDataState2 {
        return {
            [Proto.mediaarchiver.EPGType.DECLARATIVE]: { epgs: isNull(this.declarative) ? [] : [this.declarative] },
            [Proto.mediaarchiver.EPGType.READJUSTED1]: {
                epgs:
                    !isNull(this.readjusted1) && !isNull(this.readjusted2)
                        ? [this.readjusted1, this.readjusted2]
                        : !isNull(this.readjusted1)
                        ? [this.readjusted1]
                        : [],
            },
            [Proto.mediaarchiver.EPGType.MUSIC]: { epgs: isNull(this.music) ? [] : [this.music] },
            [Proto.mediaarchiver.EPGType.TALK]: { epgs: isNull(this.talk) ? [] : [this.talk] },
            [Proto.mediaarchiver.EPGType.ANNOTATIONS]: { epgs: isNull(this.annotations) ? [] : [this.annotations] },
            [Proto.mediaarchiver.EPGType.AUDIENCE]: { epgs: isNull(this.audiences) ? [] : [this.audiences] },
            [Proto.mediaarchiver.EPGType.BACKGROUND]: { epgs: isNull(this.background) ? [] : [this.background] },
            [Proto.mediaarchiver.EPGType.STT]: { epgs: isNull(this.stt) ? [] : [this.stt] },
            [Proto.mediaarchiver.EPGType.BROADCASTER1]: {
                epgs:
                    !isNull(this.broadcaster1) && !isNull(this.broadcaster2)
                        ? [this.broadcaster1, this.broadcaster2]
                        : !isNull(this.broadcaster1)
                        ? [this.broadcaster1]
                        : [],
            },
            [Proto.mediaarchiver.EPGType.DECLARATIVERADIO1]: {
                epgs:
                    !isNull(this.declarativeRadio1) && !isNull(this.declarativeRadio2)
                        ? [this.declarativeRadio1, this.declarativeRadio2]
                        : !isNull(this.declarativeRadio1)
                        ? [this.declarativeRadio1]
                        : [],
            },
            [Proto.mediaarchiver.EPGType.CROSSBUCKET]: { epgs: isNull(this.crossbucket) ? [] : [this.crossbucket] },
            [Proto.mediaarchiver.EPGType.BRUTPHONO1]: {
                epgs:
                    !isNull(this.raw1) && !isNull(this.raw2) && !isNull(this.raw3) && !isNull(this.raw4)
                        ? [this.raw1, this.raw2, this.raw3, this.raw4]
                        : !isNull(this.raw1) && !isNull(this.raw2) && !isNull(this.raw3)
                        ? [this.raw1, this.raw2, this.raw3]
                        : !isNull(this.raw1) && !isNull(this.raw2)
                        ? [this.raw1, this.raw2]
                        : !isNull(this.raw1)
                        ? [this.raw1]
                        : [],
            },
            [Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET1]: {
                epgs:
                    !isNull(this.nikitaMonobucket1) &&
                    !isNull(this.nikitaMonobucket2) &&
                    !isNull(this.nikitaMonobucket3) &&
                    !isNull(this.nikitaMonobucket4)
                        ? [
                              this.nikitaMonobucket1,
                              this.nikitaMonobucket2,
                              this.nikitaMonobucket3,
                              this.nikitaMonobucket4,
                          ]
                        : !isNull(this.nikitaMonobucket1) &&
                          !isNull(this.nikitaMonobucket2) &&
                          !isNull(this.nikitaMonobucket3)
                        ? [this.nikitaMonobucket1, this.nikitaMonobucket2, this.nikitaMonobucket3]
                        : !isNull(this.nikitaMonobucket1) && !isNull(this.nikitaMonobucket2)
                        ? [this.nikitaMonobucket1, this.nikitaMonobucket2]
                        : !isNull(this.nikitaMonobucket1)
                        ? [this.nikitaMonobucket1]
                        : [],
            },
            [Proto.mediaarchiver.EPGType.EXTRACTRESULTS]: { epgs: isNull(this.extract) ? [] : [this.extract] },
            [Proto.mediaarchiver.EPGType.LEONV11]: {
                epgs:
                    !isNull(this.leonV11) && !isNull(this.leonV12) && !isNull(this.leonV13) && !isNull(this.leonV14)
                        ? [this.leonV11, this.leonV12, this.leonV13, this.leonV14]
                        : !isNull(this.leonV11) && !isNull(this.leonV12) && !isNull(this.leonV13)
                        ? [this.leonV11, this.leonV12, this.leonV13]
                        : !isNull(this.leonV11) && !isNull(this.leonV12)
                        ? [this.leonV11, this.leonV12]
                        : !isNull(this.leonV11)
                        ? [this.leonV11]
                        : [],
            },
            [Proto.mediaarchiver.EPGType.ARPP]: { epgs: isNull(this.arpp) ? [] : [this.arpp] },
            [Proto.mediaarchiver.EPGType.BACKGROUNDSTG]: {
                epgs: isNull(this.backgroundstg) ? [] : [this.backgroundstg],
            },
            [Proto.mediaarchiver.EPGType.CROSSBUCKETSTG]: {
                epgs: isNull(this.crossbucketstg) ? [] : [this.crossbucketstg],
            },
            [Proto.mediaarchiver.EPGType.MNM0]: { epgs: isNull(this.mnmRaw) ? [] : [this.mnmRaw] },
            [Proto.mediaarchiver.EPGType.MNM1]: { epgs: isNull(this.mnmProcessed) ? [] : [this.mnmProcessed] },
            [Proto.mediaarchiver.EPGType.MNMXB0]: { epgs: isNull(this.mnmxb1) ? [] : [this.mnmxb1] },
            [Proto.mediaarchiver.EPGType.SPONSORING]: { epgs: isNull(this.sponsoring) ? [] : [this.sponsoring] },
            [Proto.mediaarchiver.EPGType.MNIONLY0]: { epgs: isNull(this.mnionly1) ? [] : [this.mnionly1] },
        };
    }

    public getAudiences(): IEPGState | null {
        return this.audiences;
    }

    public getAnnotations(): IEPGState | null {
        return this.annotations;
    }

    public getBackground(): IEPGState | null {
        return this.background;
    }

    public getBackgroundSTG(): IEPGState | null {
        return this.backgroundstg;
    }

    public getARPP(): IEPGState | null {
        return this.arpp;
    }
    public getMNMXB(): IEPGState | null {
        return this.mnmxb1;
    }
    public getMNIONLY(): IEPGState | null {
        return this.mnionly1;
    }
    public getSponsoring(): IEPGState | null {
        return this.sponsoring;
    }
    public getCrossBucket(): IEPGState | null {
        return this.crossbucket;
    }
    public getCrossBucketstg(): IEPGState | null {
        return this.crossbucketstg;
    }

    public getDeclarative(): IEPGState | null {
        return this.declarative;
    }

    public getMusic(): IEPGState | null {
        return this.music;
    }

    public getReadjusted(): IEPGState[] | null {
        if (!isNull(this.readjusted1) && !isNull(this.readjusted2)) {
            return [this.readjusted1, this.readjusted2];
        }
        if (!isNull(this.readjusted1)) {
            return [this.readjusted1, []];
        }
        return null;
    }

    public getTalk(): IEPGState | null {
        return this.talk;
    }

    public getStt(): IEPGState | null {
        return this.stt;
    }

    public getResultExtract(): IEPGState | null {
        return this.extract;
    }
    public getResultProcessed(): IEPGState[] | null {
        if (
            !isNull(this.nikitaMonobucket1) &&
            !isNull(this.nikitaMonobucket2) &&
            !isNull(this.nikitaMonobucket3) &&
            !isNull(this.nikitaMonobucket4)
        ) {
            return [this.nikitaMonobucket1, this.nikitaMonobucket2, this.nikitaMonobucket3, this.nikitaMonobucket4];
        }
        if (!isNull(this.nikitaMonobucket1) && !isNull(this.nikitaMonobucket2) && !isNull(this.nikitaMonobucket3)) {
            return [this.nikitaMonobucket1, this.nikitaMonobucket2, this.nikitaMonobucket3, []];
        }
        if (!isNull(this.nikitaMonobucket1) && !isNull(this.nikitaMonobucket2)) {
            return [this.nikitaMonobucket1, this.nikitaMonobucket2, [], []];
        }
        if (!isNull(this.nikitaMonobucket1)) {
            return [this.nikitaMonobucket1, [], [], []];
        }
        return null;
    }
    public getLeonV1(): IEPGState[] | null {
        if (!isNull(this.leonV11) && !isNull(this.leonV12) && !isNull(this.leonV13) && !isNull(this.leonV14)) {
            return [this.leonV11, this.leonV12, this.leonV13, this.leonV14];
        }
        if (!isNull(this.leonV11) && !isNull(this.leonV12) && !isNull(this.leonV13)) {
            return [this.leonV11, this.leonV12, this.leonV13, []];
        }
        if (!isNull(this.leonV11) && !isNull(this.leonV12)) {
            return [this.leonV11, this.leonV12, [], []];
        }
        if (!isNull(this.leonV11)) {
            return [this.leonV11, [], [], []];
        }
        return null;
    }
    public getMNMRaw(): IEPGState | null {
        return this.mnmRaw;
    }
    public getMNMProcessed(): IEPGState | null {
        return this.mnmProcessed;
    }
    public getBrutPhonosense(): IEPGState[] | null {
        if (!isNull(this.raw1) && !isNull(this.raw2) && !isNull(this.raw3) && !isNull(this.raw4)) {
            return [this.raw1, this.raw2, this.raw3, this.raw4];
        }
        if (!isNull(this.raw1) && !isNull(this.raw2) && !isNull(this.raw3)) {
            return [this.raw1, this.raw2, this.raw3, []];
        }
        if (!isNull(this.raw1) && !isNull(this.raw2)) {
            return [this.raw1, this.raw2, [], []];
        }
        if (!isNull(this.raw1)) {
            return [this.raw1, [], [], []];
        }
        return null;
    }

    public getBroadcaster(): IEPGState[] | null {
        if (!isNull(this.broadcaster1) && !isNull(this.broadcaster2)) {
            return [this.broadcaster1, this.broadcaster2];
        }
        if (!isNull(this.broadcaster1)) {
            return [this.broadcaster1, []];
        }
        return null;
    }

    public getDeclarativeRadio(): IEPGState[] | null {
        if (!isNull(this.declarativeRadio1) && !isNull(this.declarativeRadio2)) {
            return [this.declarativeRadio1, this.declarativeRadio2];
        }
        if (!isNull(this.declarativeRadio1)) {
            return [this.declarativeRadio1, []];
        }
        return null;
    }

    private protoEPGToStoreEPG(proto: Proto.mediaarchiver.IEPG): IEPGState | null {
        const res: IEPGState = {};
        let valid = 0;

        if (isNullOrUndefined(proto.Programs)) {
            return null;
        }
        proto.Programs.forEach((i) => {
            const prg = this.protoProgramToStoreProgram(i);

            if (!isNull(prg)) {
                let time = prg.start.getTime();

                while (typeof res[time] !== 'undefined') {
                    time++;
                }
                res[time] = prg;
                valid++;
            }
        });
        return valid > 0 ? res : null;
    }

    private protoProgramToStoreProgram(proto: Proto.mediaarchiver.IEPGProgram): IEPGProgramState | null {
        if (isNullOrUndefinedOrZero(proto.Start)) {
            Logger.warn(proto, 'Program has no start value');
            return null;
        }
        if (isNullOrUndefinedOrZero(proto.DurationMS)) {
            Logger.warn(proto, 'Program has no duration');
            return null;
        }
        let start = dateFromProto(proto.Start);
        const realStart = start;
        let duration = proto.DurationMS;
        const realDuration = proto.DurationMS;

        if (start.getTime() < (this.from as Date).getTime()) {
            duration = realDuration - ((this.from as Date).getTime() - start.getTime());
            start = this.from as Date;
        }
        if (start.getTime() + duration > (this.to as Date).getTime()) {
            duration = (this.to as Date).getTime() - start.getTime();
        }

        return {
            category: isNullOrUndefinedOrEmptyString(proto.Category) ? '' : proto.Category,
            categoryText: isNullOrUndefinedOrEmptyString(proto.CategoryText) ? '' : proto.CategoryText,
            duration,
            metas: isNull(proto.Metas) ? undefined : proto.Metas,
            realDuration,
            realStart,
            start,
            subtitle: isNullOrUndefinedOrEmptyString(proto.Subtitle) ? '' : proto.Subtitle,
            synopsis: isNullOrUndefinedOrEmptyString(proto.Synopsis) ? '' : proto.Synopsis,
            title: isNullOrUndefinedOrEmptyString(proto.Title) ? '' : proto.Title,
            vignette: isNullOrUndefinedOrEmptyString(proto.Vignette) ? '' : proto.Vignette,
        };
    }
}
