import { action } from 'typesafe-actions';

import { IAudienceMinuteState, IEPGProgramState } from '../Timeline/Types';
import {
    ISetARPPPopoverAction,
    ISetBackgroundPopoverAction,
    ISetBackgroundSTGPopoverAction,
    ISetBroadcasterPopoverAction,
    ISetBrutPhonosensePopoverAction,
    ISetCrossBucketPopoverAction,
    ISetCrossBucketstgPopoverAction,
    ISetCurrentMinuteAction,
    ISetDeclarativePopoverAction,
    ISetDeclarativeRadioPopoverAction,
    ISetLeonV1PopoverAction,
    ISetMNIONLYPopoverAction,
    ISetMNMProcessedPopoverAction,
    ISetMNMRawPopoverAction,
    ISetMNMXBPopoverAction,
    ISetMusicPopoverAction,
    ISetReadjustedPopoverAction,
    ISetResultExtractPopoverAction,
    ISetResultProcessedPopoverAction,
    ISetSponsoringPopoverAction,
    ISetTalkPopoverAction,
    ISetXMLPopoverAction,
    TimelinePopoversActionTypes,
} from './Types';

export const setARPPPopover = (prg: IEPGProgramState | null): ISetARPPPopoverAction =>
    action(TimelinePopoversActionTypes.SET_ARPP_POPOVER, prg);
export const setMNMXBPopover = (prg: IEPGProgramState | null): ISetMNMXBPopoverAction =>
    action(TimelinePopoversActionTypes.SET_MNMXB_POPOVER, prg);
export const setMNIONLYPopover = (prg: IEPGProgramState | null): ISetMNIONLYPopoverAction =>
    action(TimelinePopoversActionTypes.SET_MNIONLY_POPOVER, prg);
export const setSponsoringPopover = (prg: IEPGProgramState | null): ISetSponsoringPopoverAction =>
    action(TimelinePopoversActionTypes.SET_SPONSORING_POPOVER, prg);
export const setBackgroundPopover = (prg: IEPGProgramState | null): ISetBackgroundPopoverAction =>
    action(TimelinePopoversActionTypes.SET_BACKGROUND_POPOVER, prg);
export const setBackgroundSTGPopover = (prg: IEPGProgramState | null): ISetBackgroundSTGPopoverAction =>
    action(TimelinePopoversActionTypes.SET_BACKGROUND_STG_POPOVER, prg);
export const setBroadcasterPopover = (prg: IEPGProgramState | null): ISetBroadcasterPopoverAction =>
    action(TimelinePopoversActionTypes.SET_BROADCASTER_POPOVER, prg);
export const setCurrentMinute = (minute: IAudienceMinuteState): ISetCurrentMinuteAction =>
    action(TimelinePopoversActionTypes.SET_CURRENT_MINUTE, minute);
export const setCrossBucketPopover = (prg: IEPGProgramState | null): ISetCrossBucketPopoverAction =>
    action(TimelinePopoversActionTypes.SET_CROSSBUCKET_POPOVER, prg);
export const setCrossBucketstgPopover = (prg: IEPGProgramState | null): ISetCrossBucketstgPopoverAction =>
    action(TimelinePopoversActionTypes.SET_CROSSBUCKET_STG_POPOVER, prg);
export const setDeclarativePopover = (prg: IEPGProgramState | null): ISetDeclarativePopoverAction =>
    action(TimelinePopoversActionTypes.SET_DECLARATIVE_POPOVER, prg);
export const setDeclarativeRadioPopover = (prg: IEPGProgramState | null): ISetDeclarativeRadioPopoverAction =>
    action(TimelinePopoversActionTypes.SET_DECLARATIVERADIO_POPOVER, prg);
export const setMNMRawPopover = (prg: IEPGProgramState | null): ISetMNMRawPopoverAction =>
    action(TimelinePopoversActionTypes.SET_MNM_RAW_POPOVER, prg);
export const setMNMProcessedPopover = (prg: IEPGProgramState | null): ISetMNMProcessedPopoverAction =>
    action(TimelinePopoversActionTypes.SET_MNM_PROCESSED_POPOVER, prg);
export const setMusicPopover = (prg: IEPGProgramState | null): ISetMusicPopoverAction =>
    action(TimelinePopoversActionTypes.SET_MUSIC_POPOVER, prg);
export const setReadjustedPopover = (prg: IEPGProgramState | null): ISetReadjustedPopoverAction =>
    action(TimelinePopoversActionTypes.SET_READJUSTED_POPOVER, prg);
export const setTalkPopover = (prg: IEPGProgramState | null): ISetTalkPopoverAction =>
    action(TimelinePopoversActionTypes.SET_TALK_POPOVER, prg);
export const setXMLPopover = (prg: IEPGProgramState | null): ISetXMLPopoverAction =>
    action(TimelinePopoversActionTypes.SET_XML_POPOVER, prg);
export const setBrutPhonosensePopover = (prg: IEPGProgramState | null): ISetBrutPhonosensePopoverAction =>
    action(TimelinePopoversActionTypes.SET_RESULT_RAW_POPOVER, prg);
export const setResultProcessedPopover = (prg: IEPGProgramState | null): ISetResultProcessedPopoverAction =>
    action(TimelinePopoversActionTypes.SET_RESULT_PROCESSED_POPOVER, prg);
export const setLeonV1Popover = (prg: IEPGProgramState | null): ISetLeonV1PopoverAction =>
    action(TimelinePopoversActionTypes.SET_RESULT_PROCESSED_STG_POPOVER, prg);
export const setResultExtractPopover = (prg: IEPGProgramState | null): ISetResultExtractPopoverAction =>
    action(TimelinePopoversActionTypes.SET_RESULT_EXTRACT_POPOVER, prg);
