import * as MD from '@material-ui/core';
import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import * as Proto from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { hideAnnotations, ILayoutState } from '../../../Store/Layout';
import { ITimelineState } from '../../../Store/Timeline';
import { setHeight as setTimelineHeight } from '../../../Store/Timeline/Actions';
import { IUserState } from '../../../Store/User/Types';
import { IXMLState } from '../../../Store/XML';
import { getTheme } from '../../../Themes';
import { TimelineFooter } from '../../Timeline/Footer';
import { TimelineAnnotations } from '../Annotations';
import TimelinePlayer from '../Player';
import { TimelineTimeline } from '../Timeline';
// import { StorageBugDialog } from './StorageBugDialog';

import styles from './style';

const theme = getTheme();

export interface IUIProps {
    timelineHeight: number;
}

interface IPropsFromState {
    i18n: I18N;
    layout: ILayoutState;
    localeInfos: ILocaleInfos;
    timeline: ITimelineState;
    user: IUserState;
    xml: IXMLState;
}

interface IPropsFromDispatch {
    hideAnnotations: typeof hideAnnotations;
    setTimelineHeight: typeof setTimelineHeight;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class TimelineContentComponent extends React.Component<AllProps> {
    // TODO: persist
    public state: IUIProps = {
        timelineHeight: 0,
    };

    private previousHeight = 0;

    public componentDidMount(): void {
        this.previousHeight = this.calcTimelineHeight(0);
    }

    public render(): React.ReactNode {
        let showTimeline = true;
        const duration = this.props.timeline.criterias.end.getTime() - this.props.timeline.criterias.start.getTime();
        let timelineHeight = this.state.timelineHeight;
        this.previousHeight = this.calcTimelineHeight(this.previousHeight);

        if (duration < 30000) {
            window.setTimeout(() => this.props.hideAnnotations(), 100);
            showTimeline = false;
            timelineHeight = 55;
        }

        return (
            <MD.Grid container={true} className={this.props.classes.root} spacing={0}>
                {/* <Route component={StorageBugDialog} /> */}
                <MD.Grid className={this.props.classes.middle} item={true} xs={12}>
                    <MD.Grid className={this.props.classes.middleContainer} container={true}>
                        <MD.Grid
                            className={this.props.classes.playerContainer}
                            item={true}
                            sm={this.props.layout.showAnnotations ? 6 : 12}
                        >
                            <Route component={TimelinePlayer} />
                        </MD.Grid>
                        {this.props.layout.showAnnotations ? (
                            <MD.Grid className={this.props.classes.annotations} item sm={6}>
                                <Route component={TimelineAnnotations} />
                            </MD.Grid>
                        ) : (
                            ''
                        )}
                    </MD.Grid>
                </MD.Grid>
                <MD.Grid
                    className={this.props.classes.timeline}
                    item
                    style={{
                        maxHeight: timelineHeight,
                        minHeight: timelineHeight,
                    }}
                    xs={12}
                >
                    {showTimeline ? <Route component={TimelineTimeline} /> : ''}
                    <Route component={TimelineFooter} />
                </MD.Grid>
            </MD.Grid>
        );
    }

    private calcTimelineHeight(previousHeight: number): number {
        let newHeight = this.props.timeline.criterias.contentTypes.reduce((height, type) => {
            switch (type) {
                case Proto.mediaarchiver.EPGType.BROADCASTER1:
                case Proto.mediaarchiver.EPGType.DECLARATIVE:
                case Proto.mediaarchiver.EPGType.DECLARATIVERADIO1:
                case Proto.mediaarchiver.EPGType.READJUSTED1:
                    return height + 34;
                case Proto.mediaarchiver.EPGType.ARPP:
                case Proto.mediaarchiver.EPGType.BACKGROUND:
                case Proto.mediaarchiver.EPGType.BACKGROUNDSTG:
                case Proto.mediaarchiver.EPGType.CROSSBUCKET:
                case Proto.mediaarchiver.EPGType.METADATA:
                case Proto.mediaarchiver.EPGType.CROSSBUCKETSTG:
                case Proto.mediaarchiver.EPGType.EXTRACTRESULTS:
                case Proto.mediaarchiver.EPGType.MNMXB0:
                case Proto.mediaarchiver.EPGType.MNIONLY0:
                case Proto.mediaarchiver.EPGType.MUSIC:
                case Proto.mediaarchiver.EPGType.SPONSORING:
                case Proto.mediaarchiver.EPGType.MNM0:
                case Proto.mediaarchiver.EPGType.MNM1:
                case Proto.mediaarchiver.EPGType.TALK:
                    return height + 20;
                case Proto.mediaarchiver.EPGType.AUDIENCE:
                    return height + 100;
                case Proto.mediaarchiver.EPGType.LEONV11:
                case Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET1:
                case Proto.mediaarchiver.EPGType.BRUTPHONO1:
                    return height + 62;
                default:
                    return height;
            }
        }, 24 + 30 + 25 + theme.mediaarchiver.dimensions.footerHeight);
        newHeight += this.props.xml.files.size * 40;
        if (previousHeight !== newHeight) {
            this.setState({ timelineHeight: newHeight });
            this.props.setTimelineHeight(newHeight);
        }
        return newHeight;
    }
}
