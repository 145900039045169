import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import { getPanels, update as getMedias } from '../../Store/Medias/Actions';
import { IMedia, IMediasState } from '../../Store/Medias/Types';
import { getTheme } from '../../Themes';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../Utils/Various';

const theme = getTheme();

const styles = MD.createStyles({
    mediaGrid: {
        margin: '5px',
    },
    mediaList: {
        '& svg': {
            fontSize: '1.2em',
            marginRight: 5,
        },

        paddingTop: 0,
    },
    mediaListItem: {
        '& span': {
            display: 'block',
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },

        cursor: 'default',
        display: 'flex',
        flexDirection: 'row',
        //width: '100%',
    },
    mediaListItemCheckbox: {
        cursor: 'pointer',
        width: 17,
    },
    mediaListItemCheckboxActive: {
        color: 'rgb(0, 176, 255)',
    },
    mediaListItemCollapse: {
        width: 17,
    },
    mediaListItemPadding: {
        paddingLeft: theme.spacing(4),
    },
    mediaListResearch: {
        '& svg': {
            height: 'auto',
            width: '10% !important',
        },
        margin: '10px 20px',
    },
    mediaType: {
        '& p': {
            fontSize: '1.3em',
            marginBottom: 0,
            marginTop: 5,
        },
        '& svg': {
            display: 'block',
            height: 'auto',
            width: '15% !important',
        },
        '&:hover': {
            opacity: 1,
        },

        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        opacity: 0.4,
        padding: theme.spacing(1),
        paddingBottom: 0,
        transition: 'opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    mediaTypeActive: {
        opacity: 1,
    },
    mediaTypeContainer: {
        marginBottom: theme.spacing(1),
        marginTop: 0,
    },
});

interface IState {
    allowedMediaTypes: number[];
    currentType: number;
    expandedPanels: string[];
    selectedMedias: number[];
    currentMediaSearch: string;
}

interface IOwnProps {
    initialMedias?: number[];
    initialMediaType?: number;
    mediaTypes?: number[];
    onChange?: (medias: number[]) => void;
    onTypeChange?: (type: number) => void;
    single?: boolean;
    startEmpty?: boolean;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
}

interface IPropsFromDispatch {
    getMedias: typeof getMedias;
    getPanels: typeof getPanels;
}

type AllProps = MD.WithStyles<typeof styles> &
    IOwnProps &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class MediaListComponent extends React.Component<AllProps, IState> {
    private initialized = false;
    private mediasBeforeTypeSwitch: number[] = [];

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState(props);
    }

    public render(): React.ReactNode {
        if (!this.initialized && !this.props.startEmpty) {
            if (this.state.selectedMedias.length > 0) {
                this.initialized = true;
            } else {
                this.props.medias.panels.tvs.forEach((panel) => {
                    if (panel.ID === 101 && Array.isArray(panel.Medias)) {
                        let selectedMedias = panel.Medias;

                        if (this.props.single) {
                            selectedMedias = selectedMedias.slice(0, 1);
                        }
                        window.setTimeout(() => {
                            this.setMedias(selectedMedias);
                        }, 500);
                        this.initialized = true;
                    }
                });
            }
        }

        const { classes } = this.props;
        const panels = this.buildPanelList();
        const radiosClasses = classNames({
            [classes.mediaType]: true,
            [classes.mediaTypeActive]: this.state.currentType === 1,
        });
        const tvsClasses = classNames({
            [classes.mediaType]: true,
            [classes.mediaTypeActive]: this.state.currentType === 2,
        });
        const countRadios = this.state.selectedMedias.reduce<number>((count, i) => {
            this.props.medias.data.some((m) => {
                if (m.id === i) {
                    if (m.type === 1) {
                        count = count + 1;
                    }
                    return true;
                }
                return false;
            });
            return count;
        }, 0);
        const countTVs = this.state.selectedMedias.reduce<number>((count, i) => {
            this.props.medias.data.some((m) => {
                if (m.id === i) {
                    if (m.type === 2) {
                        count = count + 1;
                    }
                    return true;
                }
                return false;
            });
            return count;
        }, 0);

        return (
            <MD.Grid container>
                {this.state.allowedMediaTypes.length > 1 ? (
                    <MD.Grid item className={this.props.classes.mediaListResearch} xs={12}>
                        <MD.TextField
                            id='search-textfield'
                            label={this.props.i18n._('Search Media')}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant='outlined'
                            size='small'
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                this.setState({ currentMediaSearch: event.target.value })
                            }
                            value={this.state.currentMediaSearch}
                        ></MD.TextField>
                    </MD.Grid>
                ) : (
                    ''
                )}
                {this.state.allowedMediaTypes.length > 1 ? (
                    <React.Fragment>
                        {this.state.allowedMediaTypes.indexOf(1) !== -1 ? (
                            <MD.Grid className={classes.mediaTypeContainer} item xs={6}>
                                <div
                                    className={tvsClasses}
                                    onClick={() => {
                                        let selectedMedias = this.state.selectedMedias;

                                        if (this.state.currentType !== 2) {
                                            selectedMedias = this.mediasBeforeTypeSwitch;
                                            this.mediasBeforeTypeSwitch = this.state.selectedMedias;
                                        }
                                        this.setState({ currentType: 2, selectedMedias });
                                        if (!isNullOrUndefined(this.props.onTypeChange)) {
                                            this.props.onTypeChange(2);
                                        }
                                        if (!isNullOrUndefined(this.props.onChange)) {
                                            this.props.onChange(selectedMedias);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={FA.faTvRetro} />
                                    <p>TV {!this.props.single ? `(${countTVs})` : ''}</p>
                                </div>
                            </MD.Grid>
                        ) : (
                            ''
                        )}
                        {this.state.allowedMediaTypes.indexOf(2) !== -1 ? (
                            <MD.Grid className={classes.mediaTypeContainer} item xs={6}>
                                <div
                                    className={radiosClasses}
                                    onClick={() => {
                                        let selectedMedias = this.state.selectedMedias;

                                        if (this.state.currentType !== 1) {
                                            selectedMedias = this.mediasBeforeTypeSwitch;
                                            this.mediasBeforeTypeSwitch = this.state.selectedMedias;
                                        }
                                        this.setState({ currentType: 1, selectedMedias });
                                        if (!isNullOrUndefined(this.props.onTypeChange)) {
                                            this.props.onTypeChange(1);
                                        }
                                        if (!isNullOrUndefined(this.props.onChange)) {
                                            this.props.onChange(selectedMedias);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={FA.faMusic} />
                                    <p>Radio {!this.props.single ? `(${countRadios})` : ''}</p>
                                </div>
                            </MD.Grid>
                        ) : (
                            ''
                        )}
                    </React.Fragment>
                ) : (
                    ''
                )}
                <MD.Grid item xs={12}>
                    <MD.List className={classes.mediaList} dense={true}>
                        {Array.from(panels).map((v: [string, IMedia[]]) => {
                            const checkboxClasses = classNames({
                                [classes.mediaListItemCheckbox]: true,
                                [classes.mediaListItemCheckboxActive]: v[1].some(
                                    (m) => this.state.selectedMedias.indexOf(m.id) !== -1,
                                ),
                            });
                            let name = v[0];

                            const countChecked = v[1].filter(
                                (m) => this.state.selectedMedias.indexOf(m.id) !== -1,
                            ).length;

                            if (countChecked && this.state.expandedPanels.indexOf(v[0]) === -1) {
                                name = `${name} ${this.getSelectedMediasName()}`;
                            }

                            return (
                                <div key={`panel_${v[0]}`}>
                                    <MD.ListItem
                                        button
                                        className={classes.mediaListItem}
                                        onClick={() => {
                                            let list = this.state.expandedPanels;

                                            if (list.indexOf(v[0]) === -1) {
                                                list.push(v[0]);
                                            } else {
                                                list = list.filter((i) => i !== v[0]);
                                            }
                                            this.setState({ expandedPanels: list });
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            className={checkboxClasses}
                                            icon={
                                                v[1].some((m) => this.state.selectedMedias.indexOf(m.id) === -1)
                                                    ? v[1].some((m) => this.state.selectedMedias.indexOf(m.id) !== -1)
                                                        ? FA.faMinusSquare
                                                        : FA.faSquare
                                                    : FA.faCheckSquare
                                            }
                                            onClick={(ev: React.MouseEvent) => {
                                                if (this.props.single) {
                                                    return;
                                                }
                                                ev.preventDefault();
                                                ev.stopPropagation();

                                                let list = this.state.selectedMedias;
                                                const ids: number[] = v[1].map((m) => m.id);

                                                list = v[1].some((m) => this.state.selectedMedias.indexOf(m.id) === -1)
                                                    ? list.concat(ids)
                                                    : list.filter((m) => ids.indexOf(m) === -1);
                                                this.setMedias(list);
                                            }}
                                        />
                                        {!!this.props.single ? (
                                            <span>{`${name}`}</span>
                                        ) : (
                                            <span>{`${v[0]} (${countChecked})`}</span>
                                        )}
                                        <FontAwesomeIcon
                                            className={classes.mediaListItemCollapse}
                                            icon={
                                                this.state.currentMediaSearch !== ''
                                                    ? FA.faChevronDown
                                                    : this.state.expandedPanels.indexOf(v[0]) !== -1
                                                    ? FA.faChevronDown
                                                    : FA.faChevronUp
                                            }
                                        />
                                    </MD.ListItem>
                                    <MD.Collapse
                                        in={
                                            this.state.currentMediaSearch !== '' ||
                                            this.state.expandedPanels.indexOf(v[0]) !== -1
                                        }
                                    >
                                        <MD.List className={classes.mediaList} dense={true}>
                                            {v[1].map((m: IMedia) => {
                                                const className = classNames({
                                                    [classes.mediaListItem]: true,
                                                    [classes.mediaListItemPadding]: true,
                                                });
                                                const checkboxClassesItem = classNames({
                                                    [classes.mediaListItemCheckbox]: true,
                                                    [classes.mediaListItemCheckboxActive]:
                                                        this.state.selectedMedias.indexOf(m.id) !== -1,
                                                });

                                                return (
                                                    <MD.ListItem
                                                        button
                                                        className={className}
                                                        key={`panel_media_${m.id}`}
                                                        onClick={() => {
                                                            let list = this.state.selectedMedias;

                                                            if (this.props.single) {
                                                                list =
                                                                    this.state.selectedMedias.indexOf(m.id) === -1
                                                                        ? [m.id]
                                                                        : [];
                                                            } else {
                                                                list =
                                                                    this.state.selectedMedias.indexOf(m.id) === -1
                                                                        ? list.concat([m.id])
                                                                        : list.filter((i) => i !== m.id);
                                                            }
                                                            this.setMedias(list);
                                                            this.setState({ currentMediaSearch: '' });
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            className={checkboxClassesItem}
                                                            icon={
                                                                this.state.selectedMedias.indexOf(m.id) === -1
                                                                    ? FA.faSquare
                                                                    : FA.faCheckSquare
                                                            }
                                                        />
                                                        <span>{m.name}</span>
                                                    </MD.ListItem>
                                                );
                                            })}
                                        </MD.List>
                                    </MD.Collapse>
                                </div>
                            );
                        })}
                    </MD.List>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private buildPanelList(): Map<string, IMedia[]> {
        const panels = this.state.currentType === 2 ? this.props.medias.panels.tvs : this.props.medias.panels.radios;
        const res = new Map<string, IMedia[]>();

        panels.forEach((panel) => {
            if (!Array.isArray(panel.Medias)) {
                return true;
            }
            const medias = panel.Medias.map((id: number) => {
                let m: IMedia | null = null;

                this.props.medias.data.some((media) => {
                    if (media.id === id) {
                        if (this.state.currentMediaSearch !== '') {
                            if (media.name.toLowerCase().includes(this.state.currentMediaSearch.toLowerCase())) {
                                m = media;
                                return true;
                            } else {
                                return false;
                            }
                        }
                        m = media;
                        return true;
                    }
                    return false;
                });
                return m;
            })
                .filter((i) => !isNullOrUndefined(i))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .sort((a: any, b: any) => {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                });

            if (!isNullOrUndefinedOrEmptyString(panel.Name) && medias.length > 0) {
                res.set(panel.Name, medias as unknown as IMedia[]);
            }
        });
        return res;
    }

    private setMedias(selectedMedias: number[]) {
        let expandedPanels = this.state.expandedPanels;

        if (this.props.single && selectedMedias.length > 0) {
            expandedPanels = [];
        }
        this.setState({ expandedPanels, selectedMedias });
        if (!isNullOrUndefined(this.props.onChange)) {
            this.props.onChange(selectedMedias);
        }
    }

    private getSelectedMediasName(): string {
        if (!this.props.single || this.state.selectedMedias.length !== 1) {
            return '';
        }
        const mediaID = this.state.selectedMedias[0];
        let mediaName = '';

        this.props.medias.data.some((m) => {
            if (m.id === mediaID) {
                mediaName = m.name;
                return true;
            }
            return false;
        });
        return mediaName === '' ? '' : `( ${mediaName} )`;
    }

    private getDefaultState(props: AllProps): IState {
        let allowedMediaTypes: number[] = [1, 2];
        let currentType = 2;
        let selectedMedias = Array.isArray(props.initialMedias) ? props.initialMedias : [];

        if (this.props.single) {
            selectedMedias = selectedMedias.slice(0, 1);
        }

        if (Array.isArray(props.mediaTypes) && props.mediaTypes.length > 0) {
            allowedMediaTypes = props.mediaTypes;
        }
        if (!isNullOrUndefined(props.initialMediaType) && allowedMediaTypes.indexOf(props.initialMediaType) !== -1) {
            currentType = props.initialMediaType;
        } else if (allowedMediaTypes.indexOf(currentType) === -1) {
            currentType = allowedMediaTypes[0];
        }
        return {
            allowedMediaTypes,
            currentMediaSearch: '',
            currentType,
            expandedPanels: [],
            selectedMedias,
        };
    }
}

const mapStateToProps = ({ i18n, medias }: IApplicationState) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMedias: () => dispatch(getMedias()),
    getPanels: () => dispatch(getPanels()),
});

export const MediaList = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(MediaListComponent));
