import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import moment from 'moment';
// import { DateTimePicker } from '@material-ui/pickers';
import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

import { openTimeline as trackOpen } from '../../../Analytics/Events';
import * as Proto from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import { setTimelineAnnotation } from '../../../Store/Annotations';
import { setTimelineTalk } from '../../../Store/Talk';
import { I18N, ILocaleInfos, setLocale } from '../../../Store/I18n';
import { setDrawerContent } from '../../../Store/Layout';
import { IMedia, IMediasState, MediaTypes, update as updateMedias, getSttMedias } from '../../../Store/Medias';
import { IPlayerState, setEndSelection, setSnapshot, setStartSelection } from '../../../Store/Player';
import { setTimelineProgram } from '../../../Store/Search';
import { setCopiedTalks } from '../../../Store/Talk';
import {
    ITimelineCriteriasState,
    ITimelineState,
    dateWarning,
    groupsForWarningDisplay,
    setCalendarStart,
    setCalendarStartWarning,
    showReportBug,
    showStorageBug,
    updateCriterias,
    warningMsg,
} from '../../../Store/Timeline';
import { IIncidentState, showIncident } from '../../../Store/Incident';
import { IUserState } from '../../../Store/User';
import { TypedStorage } from '../../../Utils/TypedStorage';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyArray,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../../Utils/Various';
import { MediaList } from '../../Medias/List';
import { TimelineDrawerCalendar } from '../DrawerCalendar';
import {
    clear as clearSearchSTT,
    searchSpeeches as doSearchSTT,
    searchSpeechesDate as doSearchSTTByDate,
    setRequestedCurrent,
    setRequestedEnd,
    setRequestedMedia,
    setRequestedStart,
} from '../../../Store/SearchSTT/Actions';
import { ISearchSTTState } from '../../../Store/SearchSTT/Types';
import {
    getPanels,
    update as getMedias,
    getBackgroundMedias,
    getCrossBucketMedias,
} from '../../../Store/Medias/Actions';

import styles from './style';

interface IDrawerUIValuesProps {
    activeType: MediaTypes;
    contentTypes: Proto.mediaarchiver.EPGType[];
    duration: number;
    media: number;
    mediaName: string;
    start: Date;
    timezone: string;
}

interface IDrawerUIErrorsProps {
    contentTypes: string;
    duration: string;
    media: string;
    start: string;
}

export interface IUIProps {
    errors: IDrawerUIErrorsProps;
    readyToSubmit: boolean;
    values: IDrawerUIValuesProps;
}

interface IPropsFromState {
    i18n: I18N;
    incident: IIncidentState;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    player: IPlayerState;
    searchAnnotation: Proto.mediaarchiver.IAnnotation | null;
    searchProgram: Proto.mediaarchiver.ISearchResult | null;
    searchSTT: ISearchSTTState;
    searchTalk: Proto.mediaarchiver.ITalk | null;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    clearSearchSTT: typeof clearSearchSTT;
    doSearchSTT: typeof doSearchSTT;
    doSearchSTTByDate: typeof doSearchSTTByDate;
    getBackgroundMedias: typeof getBackgroundMedias;
    getCrossBucketMedias: typeof getCrossBucketMedias;
    getMedias: typeof getMedias;
    getPanels: typeof getPanels;
    getSttMedias: typeof getSttMedias;
    loadI18n: typeof setLocale;
    setCalendarStart: typeof setCalendarStart;
    setCalendarStartWarning: typeof setCalendarStartWarning;
    setCopiedTalks: typeof setCopiedTalks;
    setDrawerContent: typeof setDrawerContent;
    setEndSelection: typeof setEndSelection;
    setRequestedCurrent: typeof setRequestedCurrent;
    setRequestedEnd: typeof setRequestedEnd;
    setRequestedMedia: typeof setRequestedMedia;
    setRequestedStart: typeof setRequestedStart;
    setSnapshot: typeof setSnapshot;
    setStartSelection: typeof setStartSelection;
    setTimelineAnnotation: typeof setTimelineAnnotation;
    setTimelineProgram: typeof setTimelineProgram;
    setTimelineTalk: typeof setTimelineTalk;
    showIncident: typeof showIncident;
    showReportBug: typeof showReportBug;
    showStorageBug: typeof showStorageBug;
    updateCriterias: typeof updateCriterias;
    updateMedias: typeof updateMedias;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export class TimelineDrawerComponent extends React.Component<AllProps, IUIProps> {
    public constructor(props: AllProps) {
        super(props);
        this.state = this.getInitialState();
    }

    public componentDidMount(): void {
        this.props.getMedias();
        this.props.getPanels();
        this.setStartWarning(this.state.values.start);
        this.props.getBackgroundMedias();
        this.props.getCrossBucketMedias();
        //this.props.getSttMedias();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { medias = {} as any } = this.props;

        if (!medias.data || medias.data.length === 0) {
            this.props.updateMedias();
        }
        window.setTimeout(() => {
            this.setState({ readyToSubmit: this.checkValues(false) });
        }, 500);
    }

    componentDidUpdate(_: Readonly<AllProps>, prevState: Readonly<IUIProps>): void {
        if (
            prevState.values.media !== this.state.values.media ||
            prevState.values.activeType !== this.state.values.activeType
        ) {
            this.checkContentID();
        }
    }

    public render(): React.ReactNode {
        const { classes } = this.props;
        if (!isNull(this.props.searchProgram)) {
            this.props.setTimelineAnnotation(null);
            this.props.setTimelineProgram(null);
            this.props.setTimelineTalk(null);
            let values: IDrawerUIValuesProps = {
                ...this.state.values,
                contentTypes: [Proto.mediaarchiver.EPGType.DECLARATIVE, Proto.mediaarchiver.EPGType.ANNOTATIONS],
                duration: 4,
                media: this.props.searchProgram.MediaID as number,
                mediaName: this.props.searchProgram.MediaName as string,
                start: new Date((this.props.searchProgram.Start as unknown as number) - 2 * 60 * 60 * 1000),
            };
            if (
                !isNullOrUndefinedOrEmptyArray(this.props.medias.sttMedias) &&
                !isNullOrUndefinedOrZero(this.props.searchProgram.MediaID)
            ) {
                if (this.props.medias.sttMedias.includes(this.props.searchProgram.MediaID)) {
                    values = {
                        ...this.state.values,
                        contentTypes: [Proto.mediaarchiver.EPGType.STT],
                        duration: 4,
                        media: this.props.searchProgram.MediaID as number,
                        mediaName: this.props.searchProgram.MediaName as string,
                        start: new Date((this.props.searchProgram.Start as unknown as number) - 2 * 60 * 60 * 1000),
                    };
                }
            }
            const playerStart = new Date(this.props.searchProgram.Start as unknown as number);
            this.props.setTimelineAnnotation(null);
            this.props.setTimelineProgram(null);
            this.props.setTimelineTalk(null);
            window.setTimeout(() => {
                this.props.setSnapshot(null);
                this.setStartWarning(playerStart);
                this.props.setCalendarStart(playerStart);
                this.setState({
                    ...this.state,
                    values,
                });
                this.generateTimeline(playerStart);
            }, 1000);
        } else if (!isNullOrUndefined(this.props.searchAnnotation)) {
            let mediaName = 'N/A';
            this.props.medias.data.some((media) => {
                if (isNullOrUndefined(this.props.searchAnnotation)) {
                    return false;
                }
                if (media.id === this.props.searchAnnotation.MediaID) {
                    mediaName = media.name;
                    return true;
                }
                return false;
            });
            let values: IDrawerUIValuesProps = {
                ...this.state.values,
                contentTypes: [Proto.mediaarchiver.EPGType.ANNOTATIONS],
                duration: 4,
                media: this.props.searchAnnotation.MediaID as number,
                mediaName,
                start: new Date((this.props.searchAnnotation as unknown as number) - 2 * 60 * 60 * 1000),
            };

            if (
                !isNullOrUndefinedOrEmptyArray(this.props.medias.sttMedias) &&
                !isNullOrUndefinedOrZero(this.props.searchAnnotation.MediaID)
            ) {
                if (this.props.medias.sttMedias.includes(this.props.searchAnnotation.MediaID)) {
                    values = {
                        ...this.state.values,
                        contentTypes: [Proto.mediaarchiver.EPGType.ANNOTATIONS, Proto.mediaarchiver.EPGType.STT],
                        duration: 4,
                        media: this.props.searchAnnotation.MediaID as number,
                        mediaName,
                        start: new Date((this.props.searchAnnotation as unknown as number) - 2 * 60 * 60 * 1000),
                    };
                }
            }

            const playerStart = new Date(this.props.searchAnnotation.Start as unknown as number);
            this.props.setTimelineAnnotation(null);
            this.props.setTimelineProgram(null);
            this.props.setTimelineTalk(null);
            window.setTimeout(() => {
                this.props.setSnapshot(null);
                this.setStartWarning(playerStart);
                this.props.setCalendarStart(playerStart);
                this.setState({
                    ...this.state,
                    values,
                });
                this.generateTimeline(playerStart);
            }, 1000);
        } else if (!isNullOrUndefined(this.props.searchTalk)) {
            let mediaName = 'N/A';
            this.props.medias.data.some((media) => {
                if (!isNullOrUndefined(this.props.searchTalk) && media.id === this.props.searchTalk.Media) {
                    mediaName = media.name;
                    return true;
                }
                return false;
            });

            let values: IDrawerUIValuesProps = {
                ...this.state.values,
                contentTypes: [Proto.mediaarchiver.EPGType.TALK, Proto.mediaarchiver.EPGType.ANNOTATIONS],
                duration: 4,
                media: this.props.searchTalk.Media as number,
                mediaName,
                start: new Date((this.props.searchTalk as unknown as number) - 2 * 60 * 60 * 1000),
            };
            if (
                !isNullOrUndefinedOrEmptyArray(this.props.medias.sttMedias) &&
                !isNullOrUndefinedOrZero(this.props.searchTalk.Media)
            ) {
                if (this.props.medias.sttMedias.includes(this.props.searchTalk.Media)) {
                    values = {
                        ...this.state.values,
                        contentTypes: [
                            Proto.mediaarchiver.EPGType.DECLARATIVE,
                            Proto.mediaarchiver.EPGType.READJUSTED1,
                            Proto.mediaarchiver.EPGType.READJUSTED2,
                            Proto.mediaarchiver.EPGType.TALK,
                            Proto.mediaarchiver.EPGType.ANNOTATIONS,
                            Proto.mediaarchiver.EPGType.STT,
                        ],
                        duration: 4,
                        media: this.props.searchTalk.Media as number,
                        mediaName,
                        start: new Date((this.props.searchTalk as unknown as number) - 2 * 60 * 60 * 1000),
                    };
                }
            }
            let playerStart = new Date();
            if (
                !isNullOrUndefinedOrEmptyArray(this.props.searchTalk.Speakers) &&
                !isNullOrUndefined(this.props.searchTalk.Speakers[0]) &&
                !isNullOrUndefined(this.props.searchTalk.Speakers[0].Start)
            ) {
                playerStart = new Date(this.props.searchTalk.Speakers[0].Start as number);
            }
            this.props.setTimelineAnnotation(null);
            this.props.setTimelineProgram(null);
            this.props.setTimelineTalk(null);
            window.setTimeout(() => {
                this.props.setSnapshot(null);
                this.setStartWarning(playerStart);
                this.props.setCalendarStart(playerStart);
                this.setState({
                    ...this.state,
                    values,
                });
                this.generateTimeline(playerStart);
            }, 1000);
        }
        return (
            <div className={classes.root}>
                <form className={classes.form} autoComplete='off'>
                    {this.buildMediaSelect()}
                    {this.state.values.media > 0 ? this.buildContentTypesSelect() : ''}
                    {isNullOrUndefinedOrEmptyString(this.props.timeline.criterias.calendar.startWarning) ? (
                        ''
                    ) : (
                        <Alert className={this.props.classes.startWarning} severity='warning'>
                            {this.props.timeline.criterias.calendar.startWarning}
                        </Alert>
                    )}
                    <MD.FormControl
                        className={
                            this.props.classes.formControl +
                            ' ' +
                            (this.props.timeline.startContentStep === 4 ? this.props.classes.flashit : '')
                        }
                    >
                        <Route component={TimelineDrawerCalendar} />
                    </MD.FormControl>
                </form>
                <div className={classes.updateButtonContainer}>
                    <MD.Fab
                        className={
                            classes.updateButton +
                            ' ' +
                            (this.state.readyToSubmit ? '' : classes.updateButtonDisabled) +
                            ' ' +
                            (this.props.timeline.startContentStep === 6 ? this.props.classes.flashit : '')
                        }
                        component='a'
                        onClick={this.generateTimeline.bind(this, undefined)}
                        title={this.props.i18n._('Generate timeline')}
                        variant='extended'
                    >
                        <FontAwesomeIcon icon={Icons.faSlidersH} className={classes.updateIcon} />
                        {this.props.i18n._('Timeline')}
                    </MD.Fab>
                    <MD.Button component='a' onClick={() => this.props.showReportBug()} variant='text'>
                        <FontAwesomeIcon icon={Icons.faExclamationTriangle} className={classes.updateIcon} />
                        Signaler un problème
                    </MD.Button>
                    {!isNullOrUndefinedOrEmptyArray(this.props.incident.incidentContent.incidentMessages) ? (
                        <MD.Button
                            // color='error'
                            component='p'
                            onClick={() => this.props.showIncident()}
                            style={{
                                color: 'red', // à cause de color = 'error' qui ne marche pas laaaaa
                                cursor: 'pointer !important',
                                opacity: '1 !important',
                            }}
                            variant='text'
                        >
                            <FontAwesomeIcon icon={Icons.faExclamationTriangle} className={classes.updateIcon} />
                            Incident en cours
                        </MD.Button>
                    ) : (
                        <MD.Button
                            color='secondary'
                            component='p'
                            onClick={() => this.props.showIncident()}
                            style={{
                                cursor: 'pointer !important',
                                opacity: '1 !important',
                            }}
                            variant='text'
                        >
                            <FontAwesomeIcon icon={Icons.faCheck} className={classes.updateIcon} />
                            Service normal
                        </MD.Button>
                    )}
                </div>
            </div>
        );
    }

    private onMediaChange(media: number) {
        let activeType = this.state.values.activeType;
        let mediaName = '';
        let timezone = 'Europe/Paris';

        if (isNullOrUndefinedOrZero(media)) {
            this.setState({
                readyToSubmit: false,
                values: {
                    ...this.state.values,
                    media: 0,
                    mediaName: '',
                },
            });
            return;
        }

        if (
            !this.props.medias.data.some((m: IMedia) => {
                if (m.id === media) {
                    activeType = m.type;
                    mediaName = m.name;
                    if (!isNullOrUndefinedOrEmptyString(m.timezone)) {
                        timezone = m.timezone;
                    }
                    return true;
                }
                return false;
            })
        ) {
            return;
        }

        if (mediaName === '') {
            return;
        }

        const contentTypes =
            activeType === MediaTypes.TV
                ? TypedStorage.get('lastContentTypesTV', [])
                : TypedStorage.get('lastContentTypesRadio', []);

        this.setState({
            values: { ...this.state.values, activeType, contentTypes, media, mediaName, timezone },
        });

        if (activeType === MediaTypes.TV) {
            TypedStorage.set('lastMediaTV', media);
            TypedStorage.set('lastMediaTVName', mediaName);
        } else {
            TypedStorage.set('lastMediaRadio', media);
            TypedStorage.set('lastMediaRadioName', mediaName);
        }
        window.setTimeout(() => {
            this.setState({ readyToSubmit: this.checkValues(false) });
        }, 50);
    }

    private onTypeChange(activeType: number) {
        let contentTypes: Proto.mediaarchiver.EPGType[];
        let media: number;
        let mediaName: string;
        if (activeType === MediaTypes.TV) {
            contentTypes = TypedStorage.get('lastContentTypesTV', []);
            media = TypedStorage.get('lastMediaTV', 0);
            mediaName = TypedStorage.get('lastMediaTVName', '');
            TypedStorage.set('lastMediaType', MediaTypes.TV);
        } else {
            contentTypes = TypedStorage.get('lastContentTypesRadio', []);
            media = TypedStorage.get('lastMediaRadio', 0);
            mediaName = TypedStorage.get('lastMediaRadioName', '');
            TypedStorage.set('lastMediaType', MediaTypes.Radio);
        }
        this.setState({
            values: { ...this.state.values, activeType, contentTypes, media, mediaName },
        });
        window.setTimeout(() => {
            this.setState({ readyToSubmit: this.checkValues(false) });
        }, 50);
    }

    private onContentTypeChange(
        ev: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
    ) {
        if (ev.target === null) {
            return;
        }
        if (this.state.values.activeType === MediaTypes.TV) {
            TypedStorage.set('lastContentTypesTV', ev.target.value as unknown as Proto.mediaarchiver.EPGType[]);
        } else {
            TypedStorage.set('lastContentTypesRadio', ev.target.value as unknown as Proto.mediaarchiver.EPGType[]);
        }
        this.setState({
            values: {
                ...this.state.values,
                contentTypes: ev.target.value as Proto.mediaarchiver.EPGType[],
            },
        });
        window.setTimeout(() => {
            this.setState({ readyToSubmit: this.checkValues(false) });
        }, 50);
    }

    private onContentTypeDelete(val: Proto.mediaarchiver.EPGType) {
        const contentTypes = this.state.values.contentTypes.filter((type) => type !== val);
        this.setState({
            values: {
                ...this.state.values,
                contentTypes,
            },
        });
        if (this.state.values.activeType === MediaTypes.TV) {
            TypedStorage.set('lastContentTypesTV', contentTypes);
        } else {
            TypedStorage.set('lastContentTypesRadio', contentTypes);
        }
        window.setTimeout(() => {
            this.setState({ readyToSubmit: this.checkValues(false) });
        }, 50);
    }

    private buildMediaSelect() {
        const initials = [
            TypedStorage.get('lastMediaTV', 0),
            TypedStorage.get('lastMediaRadio', 0),
            this.state.values.media,
        ].filter((media, i: number, arr: number[]) => {
            if (media === 0) {
                return false;
            }
            return arr.indexOf(media) === i;
        });

        return (
            <Route
                render={(props) => (
                    <MediaList
                        {...props}
                        dispatch={this.props.dispatch}
                        initialMedias={initials}
                        initialMediaType={this.state.values.activeType}
                        mediaTypes={[1, 2]}
                        onChange={(selectedMedias: number[]) => {
                            this.onMediaChange(selectedMedias[0]);
                        }}
                        onTypeChange={(type: number) => {
                            this.onTypeChange(type);
                        }}
                        single={true}
                        startEmpty={true}
                    />
                )}
            />
        );
    }

    private buildContentTypesSelect() {
        const list = this.getAvailableContentTypes();
        const getNameForId = (id: Proto.mediaarchiver.EPGType) => {
            let name = '';

            list.forEach((item) => {
                if (item.id === id) {
                    name = item.label;
                    return false;
                }
            });
            return name;
        };

        return (
            <MD.FormControl
                className={
                    this.props.classes.formControl +
                    ' ' +
                    (this.props.timeline.startContentStep === 3 ? this.props.classes.flashit : '')
                }
                error={this.state.errors.contentTypes.length > 0}
            >
                <MD.InputLabel htmlFor='contentType'>{this.props.i18n._('Content type')}</MD.InputLabel>

                <MD.Select
                    error={this.state.errors.contentTypes.length > 0}
                    input={<MD.Input id='contentType' />}
                    key='contentTypes'
                    MenuProps={MenuProps}
                    multiple={true}
                    onChange={this.onContentTypeChange.bind(this)}
                    value={this.state.values.contentTypes}
                    renderValue={(selected) => (
                        <div className={this.props.classes.chips}>
                            {Array.isArray(selected) &&
                                selected
                                    .map((val) => {
                                        const name = getNameForId(val as Proto.mediaarchiver.EPGType);

                                        if (name === '') {
                                            return null;
                                        }
                                        return (
                                            <MD.Chip
                                                className={this.props.classes.chip}
                                                key={val as string}
                                                label={name}
                                                onClick={(ev: React.MouseEvent) => {
                                                    ev.preventDefault();
                                                    ev.stopPropagation();
                                                }}
                                                onDelete={this.onContentTypeDelete.bind(
                                                    this,
                                                    val as Proto.mediaarchiver.EPGType,
                                                )}
                                            />
                                        );
                                    })
                                    .filter((i) => !isNull(i))}
                        </div>
                    )}
                >
                    {list
                        .filter((item) => item.availability.indexOf(this.state.values.activeType) !== -1)
                        .filter((item) => this.props.user.user.allowedEPGs.indexOf(item.id) !== -1)
                        .filter((item) => item.availabilityMedia.includes(this.state.values.media))
                        .sort((a, b) => (a.label < b.label ? -1 : 1))
                        .map((item) => (
                            <MD.MenuItem key={item.id} value={item.id}>
                                {item.label}
                            </MD.MenuItem>
                        ))}
                </MD.Select>
                {this.state.errors.contentTypes.length > 0 && (
                    <MD.FormHelperText>{this.state.errors.contentTypes}</MD.FormHelperText>
                )}
            </MD.FormControl>
        );
    }

    private checkContentID() {
        let lastcontentradio = TypedStorage.get('lastContentTypesRadio', []);
        let lastcontenttv = TypedStorage.get('lastContentTypesTV', []);

        const list = this.getAvailableContentTypes();

        const possibleIdTV = list
            .filter((item) => item.availability.includes(MediaTypes.TV))
            .filter((item) => this.props.user.user.allowedEPGs.indexOf(item.id) !== -1)
            .filter((item) => item.availabilityMedia.includes(this.state.values.media))
            .map((item) => item.id);
        const possibleIdRadio = list
            .filter((item) => item.availability.includes(MediaTypes.Radio))
            .filter((item) => this.props.user.user.allowedEPGs.indexOf(item.id) !== -1)
            .filter((item) => item.availabilityMedia.includes(this.state.values.media))
            .map((item) => item.id);

        if (lastcontentradio.length !== 0) {
            lastcontentradio = lastcontentradio.filter((v) => possibleIdRadio.includes(v));
        }
        if (lastcontenttv.length !== 0) {
            lastcontenttv = lastcontenttv.filter((v) => possibleIdTV.includes(v));
        }

        TypedStorage.set('lastContentTypesRadio', lastcontentradio);
        TypedStorage.set('lastContentTypesTV', lastcontenttv);

        const currentSelect = this.state.values.contentTypes;
        this.setState({
            values: {
                ...this.state.values,
                contentTypes: currentSelect.filter((item) => {
                    if (this.state.values.activeType === MediaTypes.TV) {
                        return possibleIdTV.includes(item);
                    }
                    if (this.state.values.activeType === MediaTypes.Radio) {
                        return possibleIdRadio.includes(item);
                    }
                }),
            },
        });
    }

    private getAvailableContentTypes() {
        const allmedia = this.props.medias.data.map((media) => media.id);
        return [
            {
                availability: [MediaTypes.TV],
                availabilityMedia: allmedia,
                id: Proto.mediaarchiver.EPGType.BROADCASTER1,
                label: this.props.i18n._('Broadcaster EPG'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                availabilityMedia: allmedia, //this.props.medias.crossbucketMedias,
                id: Proto.mediaarchiver.EPGType.CROSSBUCKET,
                label: this.props.i18n._('Relevé musicaux'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                availabilityMedia: this.props.medias.crossbucketMedias,
                id: Proto.mediaarchiver.EPGType.CROSSBUCKETSTG,
                label: this.props.i18n._('STG - Sound design and background (Xbucket)'),
            },
            {
                availability: [MediaTypes.TV],
                availabilityMedia: allmedia,
                id: Proto.mediaarchiver.EPGType.DECLARATIVE,
                label: this.props.i18n._('Declarative EPG'),
            },
            {
                availability: [MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.DECLARATIVERADIO1,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Declarative EPG'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.BRUTPHONO1,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Brut reco'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET1,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Nikita Monobucket'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.LEONV11,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Leon V1'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.EXTRACTRESULTS,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Archives'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                availabilityMedia: allmedia,
                id: Proto.mediaarchiver.EPGType.MUSIC,
                label: this.props.i18n._('Prod Muzicast'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                availabilityMedia: allmedia,
                id: Proto.mediaarchiver.EPGType.TALK,
                label: this.props.i18n._('Politics speaking time'),
            },
            {
                availability: [MediaTypes.TV],
                availabilityMedia: allmedia,
                id: Proto.mediaarchiver.EPGType.AUDIENCE,
                label: this.props.i18n._('Mediamat Audience'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                availabilityMedia: allmedia, //this.props.medias.backgroundMedias <-- si un jour on refait les fonds sonore spécifiquement à certains media
                id: Proto.mediaarchiver.EPGType.BACKGROUND,
                label: this.props.i18n._('Sound design and background'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.BACKGROUNDSTG,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Background es STG'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.ARPP,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Spots Publicitaires'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.MNMXB0,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Relevé musicaux avec musiques non identifiées'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.MNIONLY0,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Musiques non identifiées sans relevé musicaux'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.SPONSORING,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Sponsoring'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                availabilityMedia: allmedia,
                id: Proto.mediaarchiver.EPGType.STT,
                label: this.props.i18n._('Speech To Text'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.MNM0,
                availabilityMedia: allmedia,
                label: this.props.i18n._('MPM Brut Phonosense'),
            },
            {
                availability: [MediaTypes.TV, MediaTypes.Radio],
                id: Proto.mediaarchiver.EPGType.MNM1,
                availabilityMedia: allmedia,
                label: this.props.i18n._('Total musique détectée'),
            },
            {
                availability: [MediaTypes.TV],
                availabilityMedia: allmedia,
                id: Proto.mediaarchiver.EPGType.READJUSTED1,
                label: this.props.i18n._('Readjusted EPG'),
            },
        ];
    }

    private generateTimeline(playerStart?: Date) {
        if (!this.checkValues(true)) {
            return;
        }
        const start = !isNullOrUndefined(playerStart)
            ? new Date(playerStart.getTime() - 3600000)
            : this.props.timeline.criterias.calendar.start;

        if (isNullOrUndefined(playerStart)) {
            playerStart = start;
        }

        const end = new Date(start.getTime() + this.props.timeline.criterias.calendar.duration * (60 * 60 * 1000));
        if (!isNull(this.props.player.player)) {
            this.props.player.player.start(start, end, false);
        }
        this.props.setSnapshot(null);
        this.props.setStartSelection(null);
        this.props.setEndSelection(null);
        this.props.setCopiedTalks([]);

        const criterias: ITimelineCriteriasState = {
            active: true,
            archiveTitle: '',
            calendar: this.props.timeline.criterias.calendar,
            contentTypes: this.state.values.contentTypes,
            dataToken: '',
            end,
            incrementGenerate: this.props.timeline.criterias.incrementGenerate + 1,
            media: this.state.values.media,
            mediaName: this.state.values.mediaName,
            mediaType: this.state.values.activeType,
            playerStart,
            sender: '',
            show: false,
            start,
            streamerToken: '',
            timezone: this.state.values.timezone,
            type: '',
        };
        this.props.updateCriterias(criterias);
        trackOpen(criterias);
    }

    private checkValues(updateUI: boolean): boolean {
        let hasErrors = false;
        const errors: IDrawerUIErrorsProps = {
            contentTypes: '',
            duration: '',
            media: '',
            start: '',
        };

        const setError = (what: keyof IDrawerUIErrorsProps, error: string) => {
            hasErrors = true;
            errors[what] = error;
        };

        if (this.state.values.media === 0) {
            setError('media', this.props.i18n._('Please choose a media'));
        }
        if (updateUI) {
            this.setState({ errors });
            this.forceUpdate();
        }
        return !hasErrors;
    }

    private setStartWarning(start: Date) {
        this.props.setCalendarStartWarning(
            this.shouldShowWarningForDate() && groupsForWarningDisplay.includes(this.props.user.user.group)
                ? start.getTime() < dateWarning
                    ? warningMsg
                    : ''
                : '',
        );
    }

    // only show message on weekends and week days from 22.30 to 8.00
    private shouldShowWarningForDate(): boolean {
        const now = new Date();

        if (now.getDay() === 6 || now.getDay() === 0) {
            return true;
        }
        if (now.getHours() >= 23 || now.getHours() < 8) {
            return true;
        }
        if (now.getHours() === 22 && now.getHours() >= 30) {
            return true;
        }
        return false;
    }

    private getInitialState(): IUIProps {
        const activeType = TypedStorage.get('lastMediaType', MediaTypes.TV);
        let media = 0;
        let mediaName = '';
        let contentTypes: Proto.mediaarchiver.EPGType[] = [];

        if (activeType === MediaTypes.Radio) {
            contentTypes = TypedStorage.get('lastContentTypesRadio', []);
            if (TypedStorage.get('lastMediaRadio', 0) !== 0 && TypedStorage.get('lastMediaRadioName', '') !== '') {
                media = TypedStorage.get('lastMediaRadio', 0);
                mediaName = TypedStorage.get('lastMediaRadioName', '');
            }
        } else {
            contentTypes = TypedStorage.get('lastContentTypesTV', []);
            if (TypedStorage.get('lastMediaTV', 0) !== 0 && TypedStorage.get('lastMediaTVName', '') !== '') {
                media = TypedStorage.get('lastMediaTV', 0);
                mediaName = TypedStorage.get('lastMediaTVName', '');
            }
        }

        const defaultStart = moment().subtract(1, 'day').startOf('day').toDate();
        const tsStart = TypedStorage.get('lastTimelineDate', defaultStart.getTime());

        return {
            errors: {
                contentTypes: '',
                duration: '',
                media: '',
                start: '',
            },
            readyToSubmit: false,
            values: {
                activeType,
                contentTypes,
                duration: 4,
                media,
                mediaName,
                start: new Date(tsStart),
                timezone: 'Europe/Paris',
            },
        };
    }
}
