import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as Proto from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import { getPanels, searchKinds, searchKindsReset, updateSTT as getMedias } from '../../Store/Medias/Actions';
import { IMediasState } from '../../Store/Medias/Types';
import { clear as clearSearch, searchSpeeches as doSearch } from '../../Store/SearchSTT/Actions';
import { ISearchSTTParams, ISearchSTTState } from '../../Store/SearchSTT/Types';
import { IUserState } from '../../Store/User/Types';
import { getTheme } from '../../Themes';
import { getDate, isNull, isNullOrUndefined } from '../../Utils/Various';
import { MediaList } from '../Medias/List';

const theme = getTheme();

export const styles = MD.createStyles({
    datePickers: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    datePickersError: {
        marginLeft: theme.spacing(1),
    },
    formControl: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%',
    },
    kindChips: {
        '& .MuiChip-root': {
            marginBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(1),
            maxWidth: '90%',
        },

        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
    },
    kindWarning: {
        textAlign: 'center',
    },
    root: {},
    searchButton: {
        marginTop: theme.spacing(1) * 3,
    },
    searchButtonContainer: {
        textAlign: 'center',
    },
    searchButtonDisabled: {
        '& a': {
            cursor: 'not-allowed',
        },

        opacity: 0.5,
    },
    searchIcon: {
        marginRight: theme.spacing(1),
    },
    title: {
        marginBottom: 0,
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
});

interface IStateErrors {
    date: string;
    medias: string;
    query: string;
}

interface IState {
    end: Date;
    errors: IStateErrors;
    logic: Proto.mediaarchiver.SearchSpeechesLogic;
    query: string;
    selectedMedias: number[];
    start: Date;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    searchSTT: ISearchSTTState;
    user: IUserState;
}

interface IPropsFromDispatch {
    clearSearch: typeof clearSearch;
    doSearch: typeof doSearch;
    getMedias: typeof getMedias;
    getPanels: typeof getPanels;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class SearchSTTDrawerComponent extends React.Component<AllProps, IState> {
    private searchTimer = -1;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    public componentDidMount(): void {
        this.props.getMedias();
        this.props.getPanels();
        if (!isNull(this.props.searchSTT.lastSearchSpeeches)) {
            window.setTimeout(() => {
                if (!isNull(this.props.searchSTT.lastSearchSpeeches)) {
                    this.props.getMedias();
                    this.props.getPanels();
                    this.props.doSearch(this.props.searchSTT.lastSearchSpeeches);
                }
            }, 1000);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearSearch();
    }

    public render(): React.ReactNode {
        const { classes } = this.props;
        const searchClasses = classNames({
            [classes.searchButtonContainer]: true,
            [classes.searchButtonDisabled]: !this.isSearchReady(),
        });
        const minDate = new Date(1640995200000); // for demo purpose
        // const minDate = Constants.minDateWeTV;

        return (
            <MD.Grid className={classes.root} container>
                <MD.Grid item xs={12}>
                    <MD.Typography className={classes.title} variant='h6'>
                        {this.props.i18n._('Date range')}
                    </MD.Typography>
                </MD.Grid>
                <MD.Grid item xs={6}>
                    <DatePicker
                        autoOk={true}
                        className={classes.datePickers}
                        disableToolbar
                        error={this.state.errors.date !== ''}
                        format={this.props.localeInfos.formatShortDate}
                        // keyboardIcon={<FontAwesomeIcon icon={FA.faKeyboard} />}
                        label={this.props.i18n._('Start date')}
                        leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                        // locale={this.props.localeInfos.momentLocale}
                        maxDate={moment().endOf('day')}
                        minDate={minDate}
                        onChange={this.onStartChange.bind(this)}
                        rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                        value={this.state.start}
                        variant='inline'
                    />
                </MD.Grid>
                <MD.Grid item xs={6}>
                    <DatePicker
                        autoOk={true}
                        className={classes.datePickers}
                        disableToolbar
                        error={this.state.errors.date !== ''}
                        format={this.props.localeInfos.formatShortDate}
                        // keyboardIcon={<FontAwesomeIcon icon={FA.faKeyboard} />}
                        label={this.props.i18n._('End date')}
                        leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                        // locale={this.props.localeInfos.momentLocale}
                        maxDate={moment().endOf('day')}
                        onChange={this.onEndChange.bind(this)}
                        rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                        value={this.state.end}
                        variant='inline'
                    />
                </MD.Grid>
                {this.state.errors.date !== '' ? (
                    <MD.Grid item xs={12}>
                        <MD.Typography className={classes.datePickersError} color='error' variant='body2'>
                            {this.state.errors.date}
                        </MD.Typography>
                    </MD.Grid>
                ) : (
                    ''
                )}
                <MD.Grid item xs={12}>
                    <MD.Typography className={classes.title} variant='h6'>
                        {this.props.i18n._('Media')}
                    </MD.Typography>
                </MD.Grid>
                <MD.Grid item xs={12}>
                    <Route
                        render={(props) => (
                            <MediaList
                                {...props}
                                dispatch={this.props.dispatch}
                                initialMedias={this.state.selectedMedias}
                                mediaTypes={[1, 2]}
                                onChange={(selectedMedias: number[]) => {
                                    this.setState({ selectedMedias });
                                }}
                                single={false}
                                startEmpty={true}
                            />
                        )}
                    />
                </MD.Grid>

                <MD.Grid item xs={12}>
                    <MD.Typography className={classes.title} variant='h6'>
                        {this.props.i18n._('Searched words')}
                    </MD.Typography>
                </MD.Grid>
                <MD.Grid item xs={12}>
                    <MD.FormControl className={this.props.classes.formControl}>
                        <MD.TextField
                            fullWidth
                            helperText={this.props.i18n._('Enter word(s) to search')}
                            margin='none'
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ query: ev.target.value });
                            }}
                            onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                if (event.key === 'Enter' && this.state.query !== '') {
                                    this.onSearch();
                                }
                            }}
                            value={this.state.query}
                        />
                    </MD.FormControl>
                </MD.Grid>
                <MD.Grid item xs={12}>
                    <MD.FormControl className={this.props.classes.formControl}>
                        <MD.FormLabel component='legend'>{this.props.i18n._('Select search logic')}</MD.FormLabel>
                        <MD.FormGroup>
                            <MD.FormControlLabel
                                control={
                                    <MD.Checkbox
                                        checked={
                                            this.state.logic ===
                                            Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_AND
                                        }
                                        onChange={this.onLogicChange.bind(
                                            this,
                                            Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_AND,
                                        )}
                                        name={this.props.i18n._('All words')}
                                    />
                                }
                                label={this.props.i18n._('All words')}
                            />
                            <MD.FormControlLabel
                                control={
                                    <MD.Checkbox
                                        checked={
                                            this.state.logic ===
                                            Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_OR
                                        }
                                        onChange={this.onLogicChange.bind(
                                            this,
                                            Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_OR,
                                        )}
                                        name={this.props.i18n._('One of the words')}
                                    />
                                }
                                label={this.props.i18n._('One of the words')}
                            />
                            <MD.FormControlLabel
                                control={
                                    <MD.Checkbox
                                        checked={
                                            this.state.logic ===
                                            Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_PHRASE
                                        }
                                        onChange={this.onLogicChange.bind(
                                            this,
                                            Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_PHRASE,
                                        )}
                                        name={this.props.i18n._('Exact phrase')}
                                    />
                                }
                                label={this.props.i18n._('Exact phrase')}
                            />
                        </MD.FormGroup>
                        <MD.FormHelperText>{this.props.i18n._('Please select one logic')}</MD.FormHelperText>
                    </MD.FormControl>
                </MD.Grid>
                <MD.Grid item xs={12}>
                    <div className={searchClasses}>
                        <MD.Fab
                            className={classes.searchButton}
                            component='a'
                            onClick={this.onSearch.bind(this)}
                            title={this.props.i18n._('Search')}
                            variant='extended'
                        >
                            <FontAwesomeIcon className={classes.searchIcon} icon={FA.faSearch} />
                            {this.props.i18n._('Search')}
                        </MD.Fab>
                    </div>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private onLogicChange(logic: Proto.mediaarchiver.SearchSpeechesLogic): void {
        this.setState({ logic });
    }

    private onMediaChange(media: number): void {
        let list = this.state.selectedMedias;
        if (list.includes(media)) {
            list = list.filter((item) => item !== media);
        } else {
            list.push(media);
        }
        this.setState({ selectedMedias: list });
    }

    private onStartChange(val: Date | moment.Moment | null): void {
        let start = getDate(val);
        const end = this.state.end;

        if (isNullOrUndefined(start)) {
            return;
        }

        start = moment(start).startOf('day').toDate();

        this.setState({ start });

        if (start.getTime() > end.getTime()) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: this.props.i18n._('Start date is after end date'),
                },
            });
            return;
        }

        if (this.state.end.getTime() - start.getTime() > 60 * 24 * 60 * 60 * 1000) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: this.props.i18n._('Dates gap cannot exceed 60 days'),
                },
            });
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: '',
                },
            });
        }
    }
    private onEndChange(val: Date | moment.Moment | null): void {
        let end = getDate(val);
        const start = this.state.start;

        if (isNullOrUndefined(end)) {
            return;
        }

        end = moment(end).endOf('day').toDate();

        this.setState({ end });

        if (start.getTime() > end.getTime()) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: this.props.i18n._('Start date is after end date'),
                },
            });
            return;
        }

        if (this.state.end.getTime() - start.getTime() > 60 * 24 * 60 * 60 * 1000) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: this.props.i18n._('Dates gap cannot exceed 60 days'),
                },
            });
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: '',
                },
            });
        }
    }

    private isSearchReady(): boolean {
        return (
            !this.props.searchSTT.searchLoading &&
            this.state.errors.date === '' &&
            this.state.selectedMedias.length !== 0 &&
            this.state.query !== ''
        );
    }

    private onSearch() {
        if (!this.isSearchReady()) {
            return;
        }
        this.props.clearSearch();
        this.props.doSearch({
            end: this.state.end,
            logic: this.state.logic,
            medias: this.state.selectedMedias,
            page: 0,
            pageSize: 50,
            start: this.state.start,
            text: this.state.query,
        });
    }

    private getDefaultState(): IState {
        if (!isNull(this.props.searchSTT.lastSearchSpeeches)) {
            return {
                end: this.props.searchSTT.lastSearchSpeeches.end,
                errors: {
                    date: '',
                    medias: '',
                    query: '',
                },
                logic: this.props.searchSTT.lastSearchSpeeches.logic,
                query: this.props.searchSTT.lastSearchSpeeches.text,
                selectedMedias: this.props.searchSTT.lastSearchSpeeches.medias,
                start: this.props.searchSTT.lastSearchSpeeches.start,
            };
        }
        return {
            end: moment().endOf('day').subtract(1, 'days').toDate(),
            errors: {
                date: '',
                medias: '',
                query: '',
            },
            logic: Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_AND,
            query: '',
            selectedMedias: [],
            start: moment().startOf('day').subtract(8, 'days').toDate(), // for demo new Date(1640995200000)
        };
    }
}

const mapStateToProps = ({ i18n, medias, searchSTT, user }: IApplicationState, ownProps: RouteComponentProps<{}>) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
    router: ownProps,
    searchSTT,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearSearch: () => dispatch(clearSearch()),
    doSearch: (params: ISearchSTTParams) => dispatch(doSearch(params)),
    getMedias: () => dispatch(getMedias()),
    getPanels: () => dispatch(getPanels()),
    searchKinds: (search: string) => dispatch(searchKinds(search)),
    searchKindsReset: () => dispatch(searchKindsReset()),
});

export const SearchSTTDrawer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(SearchSTTDrawerComponent));
