import { Reducer } from 'redux';

import { ITimelinePopoversState, TimelinePopoversActionTypes } from './Types';

const initialState: ITimelinePopoversState = {
    currentAudienceMinute: {
        averageRate: -1,
        marketShare: -1,
        minute: null,
        weightedBasis: -1,
    },
    popoverARPP: null,
    popoverBackground: null,
    popoverBackgroundSTG: null,
    popoverBroadcaster: null,
    popoverBrutPhonosense: null,
    popoverCrossBucket: null,
    popoverCrossBucketstg: null,
    popoverDeclarative: null,
    popoverDeclarativeRadio: null,
    popoverLeonV1: null,
    popoverMNIONLY: null,
    popoverMNMProcessed: null,
    popoverMNMRaw: null,
    popoverMNMXB: null,
    popoverMusic: null,
    popoverReadjusted: null,
    popoverResultExtract: null,
    popoverResultProcessed: null,
    popoverSponsoring: null,
    popoverTalk: null,
    popoverXML: null,
};

const reducer: Reducer<ITimelinePopoversState> = (state = initialState, action) => {
    switch (action.type) {
        case TimelinePopoversActionTypes.SET_CURRENT_MINUTE: {
            return {
                ...state,
                currentAudienceMinute: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_ARPP_POPOVER: {
            return {
                ...state,
                popoverARPP: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_MNMXB_POPOVER: {
            return {
                ...state,
                popoverMNMXB: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_MNIONLY_POPOVER: {
            return {
                ...state,
                popoverMNIONLY: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_SPONSORING_POPOVER: {
            return {
                ...state,
                popoverSponsoring: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_BACKGROUND_POPOVER: {
            return {
                ...state,
                popoverBackground: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_BACKGROUND_STG_POPOVER: {
            return {
                ...state,
                popoverBackgroundSTG: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_BROADCASTER_POPOVER: {
            return {
                ...state,
                popoverBroadcaster: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_CROSSBUCKET_POPOVER: {
            return {
                ...state,
                popoverCrossBucket: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_CROSSBUCKET_STG_POPOVER: {
            return {
                ...state,
                popoverCrossBucketstg: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_DECLARATIVE_POPOVER: {
            return {
                ...state,
                popoverDeclarative: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_DECLARATIVERADIO_POPOVER: {
            return {
                ...state,
                popoverDeclarativeRadio: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_READJUSTED_POPOVER: {
            return {
                ...state,
                popoverReadjusted: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_MNM_RAW_POPOVER: {
            return {
                ...state,
                popoverMNMRaw: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_MNM_PROCESSED_POPOVER: {
            return {
                ...state,
                popoverMNMProcessed: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_MUSIC_POPOVER: {
            return {
                ...state,
                popoverMusic: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_TALK_POPOVER: {
            return {
                ...state,
                popoverTalk: action.payload,
            };
        }

        case TimelinePopoversActionTypes.SET_XML_POPOVER: {
            return {
                ...state,
                popoverXML: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_RESULT_EXTRACT_POPOVER: {
            return {
                ...state,
                popoverResultExtract: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_RESULT_PROCESSED_POPOVER: {
            return {
                ...state,
                popoverResultProcessed: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_RESULT_PROCESSED_STG_POPOVER: {
            return {
                ...state,
                popoverLeonV1: action.payload,
            };
        }
        case TimelinePopoversActionTypes.SET_RESULT_RAW_POPOVER: {
            return {
                ...state,
                popoverBrutPhonosense: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as TimelinePopoversReducer };
